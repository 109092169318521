var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nibnut-sidenav",class:{ active: _vm.navigating }},[_c('section',{staticClass:"navbar-brand"},[(!!_vm.profile_id)?_c('base-link',{staticClass:"hide-sm",attrs:{"href":{ name: 'home' },"title":_vm.translate('Dashboard')}},[_c('app-logo'),_c('h3',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.setting("application_name")))])],1):_vm._e(),(!!_vm.profile_id)?_c('base-link',{staticClass:"show-sm",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('app-logo')],1):_vm._e()],1),(!!_vm.profile_id)?_c('ul',{staticClass:"nav"},[_c('router-link',{attrs:{"to":{ name: 'home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'home') || (_vm.$route.name === 'ui-catalog') }},[_c('base-link',{attrs:{"href":href,"title":_vm.translate('Dashboard')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"home"}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate("Dashboard")))])],1)],1)]}}],null,false,1019787887)}),(_vm.is_developer)?_c('router-link',{attrs:{"to":{ name: 'listing_showing.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'listing_showing.list') }},[_c('base-link',{attrs:{"href":href,"title":_vm.translate('Showings')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"calendar"}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate('Showings')))])],1)],1)]}}],null,false,3119651045)}):_vm._e(),(_vm.is_at_least_realtor)?_c('router-link',{attrs:{"to":{ name: 'listing.mine' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'listing.mine') }},[_c('base-link',{attrs:{"href":href,"title":_vm.translate('My Listings')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":['user', 'sign']}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate('My Listings')))])],1)],1)]}}],null,false,1699744526)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'listing.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'listing.list') }},[_c('base-link',{attrs:{"href":href,"title":_vm.translate('Listing:::Listings', {}, 2)},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"sign"}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate('Listing:::Listings', {}, 2)))])],1)],1)]}}],null,false,747788216)}),(_vm.is_at_least_realtor)?_c('router-link',{attrs:{"to":{ name: 'ping' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'ping') }},[_c('base-link',{attrs:{"href":href,"title":_vm.translate('Ping Area Intelligence')},nativeOn:{"click":function($event){return navigate($event)}}},[_c('open-icon',{attrs:{"glyph":"map-marked-alt"}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate('Ping Area Intelligence')))])],1)],1)]}}],null,false,1648463923)}):_vm._e(),(_vm.is_at_least_realtor)?_c('router-link',{attrs:{"to":{ name: 'user.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"users"}}),(_vm.is_realtor)?_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate("Clients")))]):_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate("Users")))])],1)],1)]}}],null,false,1353439667)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'settings' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"cog"}}),_c('span',{staticClass:"hide-md"},[_vm._v(_vm._s(_vm.translate("Settings")))])],1)],1)]}}],null,false,4259443806)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }