<template>
    <nav
        :class="{ active: navigating }"
        class="nibnut-sidenav"
    >
        <section
            class="navbar-brand"
        >
            <base-link
                v-if="!!profile_id"
                :href="{ name: 'home' }"
                :title="translate('Dashboard')"
                class="hide-sm"
            >
                <app-logo />
                <h3 class="hide-md">{{ setting("application_name") }}</h3>
            </base-link>
            <base-link
                v-if="!!profile_id"
                href="#"
                class="show-sm"
                @click.prevent="toggle"
            >
                <app-logo />
            </base-link>
        </section>
        <ul
            v-if="!!profile_id"
            class="nav"
        >
            <router-link
                :to="{ name: 'home' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'home') || ($route.name === 'ui-catalog') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="translate('Dashboard')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="home" />
                        <span class="hide-md">{{ translate("Dashboard") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_developer"
                :to="{ name: 'listing_showing.list' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'listing_showing.list') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="translate('Showings')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="calendar" />
                        <span class="hide-md">{{ translate('Showings') }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_realtor"
                :to="{ name: 'listing.mine' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'listing.mine') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="translate('My Listings')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon :glyph="['user', 'sign']" />
                        <span class="hide-md">{{ translate('My Listings') }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'listing.list' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'listing.list') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="translate('Listing:::Listings', {}, 2)"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="sign" />
                        <span class="hide-md">{{ translate('Listing:::Listings', {}, 2) }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_realtor"
                :to="{ name: 'ping' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'ping') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="translate('Ping Area Intelligence')"
                        @click.native="navigate($event)"
                    >
                        <open-icon glyph="map-marked-alt" />
                        <span class="hide-md">{{ translate('Ping Area Intelligence') }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_realtor"
                :to="{ name: 'user.list' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="users" />
                        <span v-if="is_realtor" class="hide-md">{{ translate("Clients") }}</span>
                        <span v-else class="hide-md">{{ translate("Users") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'settings' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="cog" />
                        <span class="hide-md">{{ translate("Settings") }}</span>
                    </base-link>
                </li>
            </router-link>
            <!--
            <li
                v-if="installable"
                class="nav-item"
            >
                <base-link
                    href="#"
                    @click.prevent="$emit('install')"
                >
                    <open-icon glyph="arrow-circle-down" />
                    <span class="hide-md">{{ translate("Install") }}</span>
                </base-link>
            </li>
            //-->
        </ul>
    </nav>
</template>

<script>
import { misc_utilities, string_utilities } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { AppLogo, BaseLink, OpenIcon } from "@/nibnut/components"

import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    mixins: [addl_profile_utilities, misc_utilities, string_utilities],
    components: {
        AppLogo,
        BaseLink,
        OpenIcon
    },
    watch: {
        $route: "stop_navigating"
    },
    methods: {
        toggle () {
            this.navigating = !this.navigating
        },
        stop_navigating () {
            this.navigating = false
        }
    },
    computed: {
        nibnut_logo () {
            return nibnut_logo
        }
    },
    props: {
        installable: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            navigating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

nav.nibnut-sidenav {
    background-color: $gray-color-dark;
    color: $gray-color-light;
    padding-bottom: 2.5rem;
    overflow: hidden;
    min-width: 15vw;
    z-index: $zindex-2;

    & > section.navbar-brand {
        height: $top-nav-height;
        white-space: nowrap;
        padding: $layout-spacing-lg;
        background-color: $gray-color-dark;
        box-shadow: 0 2px 30px 2px rgb(0 0 0 / 10%);
        border-bottom: 0.05rem solid rgba(255 255 255 / 50%);

        a {
            &, &:visited, &:hover, &:focus, &:active {
                color: $light-color;
                text-decoration: none;
            }
        }

        img.app-logo {
            display: inline-block;
            width: 1.8rem;
            vertical-align: middle;
        }
        h3 {
            display: inline;
            padding-left: 0.3rem;
            font-weight: 500;
            vertical-align: middle;
        }
    }
    & > .nav {
        margin: 0;
        background-color: $gray-color-dark;

        & > .nav-item {
            margin: 0;
            border-right: 0.25rem solid transparent;
            white-space: nowrap;
            overflow: hidden;

            & > a {
                display: block;
                padding: 0.6rem 1.5rem 0.6rem $layout-spacing-lg;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                transition: all .2s ease;

                &, &:visited, &:active {
                    color: $brand-color-light-gray;
                    text-decoration: none;
                }
                &:hover, &:focus {
                    color: $brand-color-dark-gray;
                }
                &:hover {
                    padding-left: 1.5rem;
                    padding-right: $layout-spacing-lg;
                }
                & > i.las {
                    position: relative;
                    top: 1px;
                    font-size: 1.2rem;
                    margin: 0 0.3rem;
                }
                & > span.la-stack {
                    height: 1.2rem;
                    line-height: 1.2rem;
                    width: 1.2rem;
                    margin: 0 0.3rem;

                    & > i.las:first-child {
                        font-size: 1.2rem;
                    }
                    & > i.las:last-child {
                        left: auto;
                        right: -0.2rem;
                        bottom: -0.2rem;
                        width: auto;
                        line-height: initial;
                        background: $gray-color-dark;
                    }
                }
                & > span.hide-md {
                    margin-left: $control-padding-x;
                }
            }

            &.active {
                border-right-color: $primary-color;
                background: 0 0;

                a {
                    // padding-left: 0;
                    // margin-left: 1.5rem;
                    padding-left: 1.5rem;
                    padding-right: $layout-spacing-lg;
                    border-radius: 4px;
                    font-weight: 700;
                    background: #424e58;
                    // width: calc(100% - 1.5rem);

                    &, &:visited, &:active, &:hover, &:focus {
                        color: #f6f6f6;
                        text-decoration: none;
                    }
                    & > span.la-stack {
                        & > i.las:last-child {
                            background: #424e58;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $size-md) {
        min-width: 0;
        text-align: center;
        & > .nav {
            & > .nav-item {
                & > a {
                    padding-right: $layout-spacing-lg;
                }
            }
        }
    }
    @media (max-width: $size-sm) {
        $nav-width: 3.4rem;
        background-color: transparent;
        min-width: 0;
        transition: all .2s ease;
        flex: 0 0 0;

        & > section.navbar-brand {
            width: $nav-width;
        }
        & > .nav {
            opacity: 0;
            transition: all .2s ease;
            & > .nav-item {
                & > a {
                    & > i.las {
                        margin: 0;
                    }
                    &:hover {
                        padding-left: $layout-spacing-lg;
                    }
                }
                &.active {
                    a {
                        padding-left: $layout-spacing-lg;
                    }
                }
            }
        }

        &.active {
            background-color: $gray-color-dark;
            flex: 0 0 $nav-width;
            overflow: visible;
            & > .nav {
                opacity: 1;
            }
        }
    }
}
</style>
