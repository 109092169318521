<template>
    <footer>
        <div class="columns">
            <div class="column col-6 col-sm-12">
                {{ translate("Copyright") }}&nbsp;&copy;&nbsp;{{ current_year }}&nbsp;<a :href="setting('customer_url')">{{ setting("customer_name") }}</a>&nbsp;&bull;&nbsp;v.{{ app_version }}
            </div>
            <div class="column col-6 text-right hide-sm">
                {{  translate("App sprouted from a") }}&nbsp;<a href="https://nibnut.com"><img src="@/assets/img/nibnut.png" alt="nibnut" loading="lazy" decoding="async" /></a>
            </div>
        </div>
    </footer>
</template>

<script>
import { misc_utilities, string_utilities } from "@/nibnut/mixins"

export default {
    name: "AppFooter",
    mixins: [misc_utilities, string_utilities]
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

footer {
    padding: $layout-spacing;
    background-color: $light-color;
    color: #90a4ae;
    border-top: 1px solid #E4E7ED;
    font-size: 0.7rem;
    box-shadow: 1px 0 20px rgb(0 0 0 / 10%);

    img {
        height: 1em;
    }
    @media (max-width: $size-sm) {
        & > .columns > .column:first-child {
            text-align: center;
        }
    }
}
</style>
