<template>
    <header class="navbar">
        <section class="navbar-section">
            <master-search-box
                class="mr-4"
            />
            <default-button
                v-if="false && !!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && (!!profile_id || is_public_page)"
                flavor="link"
                class="mr-4 hide-sm"
                @click.prevent="bug_report"
            >
                <open-icon glyph="bug" />
            </default-button>
        </section>
        <section class="navbar-section">
            <router-link
                v-if="is_at_least_realtor"
                :to="{ name: 'listing.edit' }"
                v-slot="{ href, navigate }"
                custom
            >
                <base-link
                    :href="href"
                    :title="translate('Add a Listing')"
                    class="btn btn-link btn-sm ml-2 hide-sm"
                    @click.native="navigate($event)"
                >
                    <open-icon glyph="plus" size="lg" />
                </base-link>
            </router-link>
            <div
                v-if="!!profile_id"
                ref="main_menu"
                :class="{ active: navigating }"
                class="dropdown dropdown-right"
            >
                <div class="btn-group">
                    <base-link
                        href="#"
                        tabindex="0"
                        :title="translate('Toggle Menu')"
                        class="btn btn-link dropdown-toggle"
                        @click.prevent="start_navigating"
                    >
                        <span class="mr-2 hide-sm">{{ profile.name }}</span>
                        <figure
                            :data-initial="profile ? profile.initials : ''"
                            class="avatar"
                        >
                            <open-icon v-if="!profile_id || (!!profile && !profile.avatar)" glyph="user" size="2x" />
                            <img v-else :src="profile.avatar" :alt="profile.name" loading="lazy" decoding="async">
                        </figure>
                    </base-link>
                    <ul class="menu">
                        <router-link
                            :to="{ name: 'profile' }"
                            v-slot="{ href, navigate }"
                            custom
                        >
                            <li
                                :class="{ 'active': ($route.name === 'profile') }"
                                class="menu-item"
                            >
                                <base-link
                                    :href="href"
                                    :title="translate('Edit Profile')"
                                    @click.native="navigate($event); stop_navigating()"
                                >
                                    <open-icon glyph="user" />
                                    {{ translate('Edit Profile') }}
                                </base-link>
                            </li>
                        </router-link>
                        <li class="divider"></li>
                        <li class="menu-item">
                            <base-link
                                href="#"
                                :title="translate('Logout')"
                                @click.prevent="logout"
                            >
                                <open-icon glyph="power-off" />
                                {{ translate("Logout") }}
                            </base-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="!!profile && !!profile.impersonated" id="impersonator" class="bg-primary">
                Impersonating {{ profile.name }}
                <default-button
                    flavor="link"
                    color="light"
                    size="sm"
                    :title="translate('Stop impersonating')"
                    @click.prevent="stop_impersonating"
                >
                    <open-icon glyph="times-circle" />
                </default-button>
            </div>
        </section>
    </header>
</template>

<script>
import { ui_utilities } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import { MasterSearchBox } from "@/custom/components"

export default {
    mixins: [addl_profile_utilities, ui_utilities],
    components: {
        DefaultButton,
        BaseLink,
        OpenIcon,
        MasterSearchBox
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        start_navigating () {
            document.body.addEventListener("click", this.maybe_stop_navigating)
            this.navigating = true
        },
        stop_navigating () {
            this.navigating = false
            document.body.removeEventListener("click", this.maybe_stop_navigating)
        },
        maybe_stop_navigating (event) {
            if(event.target && this.$refs.main_menu && !this.$refs.main_menu.contains(event.target)) {
                this.stop_navigating()
            }
        },
        stop_impersonating () {
            this.$store.dispatch("IMPERSONATE", { id: 0 }).then(response => {
                if(response && response.meta && response.meta.redirect) window.location = response.meta.redirect
                else window.location.reload()
            })
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    data () {
        return {
            navigating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

header.navbar {
    padding: $layout-spacing-lg;
    background-color: $light-color;
    height: $top-nav-height;
    box-shadow: 0 2px 30px 2px rgb(0 0 0 / 10%);

    .btn {
        color: #6b6f82;

        &:hover {
            border-color: transparent;
        }
    }
    .dropdown-toggle {
        & > span {
            position: relative;
            top: -3px;
            font-weight: 700;
        }
    }
    .avatar {
        background: $gray-color-dark;

        img {
            border-radius: 46%;
        }
    }
    #impersonator {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        padding: $control-padding-y-sm $control-padding-x-sm;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        font-size: 0.8rem;

        .btn.btn-light.btn-link {
            color: $light-color;
            &:focus,
            &:active,
            &.active {
                color: $light-color;
            }
            @include hover-supported {
                color: $light-color;
            }
        }
    }
}
</style>
